import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {
    AnswerCompetenceInput, AnswerSuggestionGQL,
    GetSuggestionGQL, GetSuggestionQuery,
    Suggestion, SuggestionLikesGQL, SuggestionLikesQuery, SuggestionResponseDto, User
} from '../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';

@Component({
    selector: 'app-suggestions-detail',
    templateUrl: './suggestions-detail.component.html',
    styleUrls: ['./suggestions-detail.component.scss']
})
export class SuggestionsDetailComponent extends BaseComponent implements OnInit {
    user = new User();
    paramId = null;
    currentUser: User = new User();
    suggestion: Suggestion = new Suggestion();
    suggestionQuery: QueryRef<GetSuggestionQuery>;
    suggestionLikes: SuggestionResponseDto = new SuggestionResponseDto();
    suggestionLikesQuery: QueryRef<SuggestionLikesQuery>;

    answer: AnswerCompetenceInput = new AnswerCompetenceInput();

    listFiles = [];
    listFilesImg = [];

    constructor(public router: Router,
                public router2: ActivatedRoute,
                public loading: NgxSpinnerService,
                public userService: UserService,
                public getSuggestionGQL: GetSuggestionGQL,
                public answerSuggestionGQL: AnswerSuggestionGQL,
                public suggestionLikesGQL: SuggestionLikesGQL,
                public translate: TranslateService) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
            }, error: err => super.onError(err)
        });
        this.userService.user.subscribe({
            next: data => {
                this.currentUser = data as User;
            }, error: err => super.onError(err)
        });
        this.router2.params.subscribe(url => {
            if (!this.isNullOrUndefined(url)) {
                this.paramId = url.id;
                this.suggestionLikesQuery = this.suggestionLikesGQL.watch({id: this.paramId});
                this.suggestionLikesQuery.valueChanges.subscribe(({data}) => {
                    this.suggestionLikes = data.suggestionLikes as SuggestionResponseDto;
                    this.suggestion = this.suggestionLikes.suggestion;
                    this.listFiles = this.suggestion.file.filter((x) => x.type !== 'jpg' && x.type !== 'png');
                    this.listFilesImg = this.suggestion.file.filter((x) => x.type === 'jpg' || x.type === 'png');
                });
            }
        });


    }

    actionAnswer() {
        if (this.isNullOrUndefined(this.answer.score) || (this.answer.score.toString() === '5' && this.isNullOrUndefined(this.answer.justificationScore))) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningRequiredFields'), 'warning');
            return;
        } else if (!this.isNullOrUndefined(this.answer.score) && this.answer.score.toString() === 'undefined') {
            this.answer.score = undefined;
            this.answer.justificationScore = undefined;
        } else if (!this.isNullOrUndefined(this.answer.score)) {
            this.answer.score = parseInt(this.answer.score.toString(), 0);
        }

        if (this.isNullOrUndefined(this.answer.answer)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningRequiredFields'), 'warning');
            return;
        }

        this.answer.id = this.paramId;
        this.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('prosseguir'), () => {
            this.onAnswer();
        });

    }

    onAnswer() {
        this.answerSuggestionGQL.mutate({input: this.answer}).subscribe((result) => {
            this.suggestionLikesQuery.refetch({id: this.paramId});
            super.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('baseComponent.warningUpdateSuccess'), 'success');
            this.router.navigate(['/suggestions']);
        });
    }

    selectProfile() {
        switch (this.user.profile) {
            case 'medical-manager':
                return false;
            case 'district-manager':
                return false;
            case 'analyst-marketing':
                return false;
            case 'product-manager':
                return true;
            case 'marketing-manager':
                return true;
            case 'admin':
                return true;
            case 'admin-local':
                return true;
            case 'consult':
                return false;
        }
    }
}
