<div class="body">
    <div class="header">
        <h1 class="header-title">
            <svg-icon class="cursor-pointer" routerLink="/suggestions" src="assets/svg/arrow.svg"></svg-icon>
            {{ 'sugerencias'|translate }}
        </h1>
    </div>
    <!--'bg-primary' case answered-->
    <div class="card-info" [ngClass]="{'bg-primary' : suggestion.status === 'answered'}">
        <div class="d-flex flex-wrap" style="gap: 13px">
            <div class="d-flex flex-wrap" style="flex-grow: 3;">
                <dl class="flex-grow-1">
                    <dt class="">{{ 'nomelinha'|translate }}</dt>
                    <dd class="">{{ suggestion.product?.productLine?.name }}</dd>
                </dl>
                <dl class="flex-grow-1">
                    <dt class="">{{ 'nomeproduto'|translate }}</dt>
                    <dd class="">{{ suggestion.product?.name }}</dd>
                </dl>
                <dl class="flex-grow-1">
                    <dt class="">{{ 'gerency'|translate }}</dt>
                    <dd class=""><span *ngFor="let m of suggestion.product?.managers">{{ m.user?.name }}<br/></span>
                    </dd>
                </dl>
            </div>
            <div class="card-divider">&nbsp;</div>
            <div class="d-flex flex-wrap flex-grow-1">
                <dl class="flex-grow-1">
                    <dt>Status</dt>
                    <dd>{{ getStatusNameByKey(suggestion.status) }}</dd>
                </dl>
                <dl class="flex-grow-1">
                    <dt>{{ 'like' | translate }}</dt>
                    <dd class="d-flex justify-content-between">
                        <span>
                            <svg-icon src="assets/svg/like.svg"></svg-icon>
                            {{ suggestionLikes.likes }}
                        </span>
                        <span>
                            <svg-icon src="assets/svg/dislike.svg"></svg-icon>
                            {{ suggestionLikes.dislikes }}
                        </span>
                    </dd>
                </dl>
            </div>
        </div>

    </div>
    <div class="d-flex flex-column mt-5 border-after" style="gap: 90px">
        <div class="row" style="row-gap: 30px">
            <app-card-profile class="col-md-4"
                              [image]="!suggestion.createdBy?.photo ? 'assets/svg/person.svg' : suggestion.createdBy?.photo"
                              [name]="suggestion.createdBy?.name"
                              [unity]="getMangerTypeByKey(suggestion.createdBy?.profile)">
            </app-card-profile>
            <div class="col-md-8">
                <div class="d-flex mb-3">
                    <span class="body-label">{{ 'criadoem'|translate }} {{ suggestion.createdAt | date: 'dd/MM/yyyy' }}</span>
                </div>
                <!--<p *ngIf="suggestion.status === 'answered'" class="body-paragraph">
                    <strong>{{'score'|translate}}:</strong> {{suggestion.score}}<br>
                    <ng-container *ngIf="!isNullOrUndefined(suggestion.justificationScore)"><strong>{{'justificationScore'|translate}}:</strong> {{suggestion.justificationScore}}<br><br></ng-container>
                    {{suggestion.answer}}
                </p>-->
                <p class="body-paragraph">
                    {{ suggestion.description }}
                </p>
            </div>
        </div>
    </div>
    <div class="flex-wrap ml-auto justify-content-end align-items-center mt-5 mb-3" style="gap: 30px;">

        <ng-container *ngIf="suggestion.status !== 'answered'">
            <div class="row col-12 px-0 mr-0">
                <app-card-profile class="col-md-4" id="answered"
                                  [borderImage]="'border-warning'"
                                  [image]="suggestion.status === 'answered' ? suggestion.answerBy?.photo : currentUser.photo"
                                  [name]="suggestion.status === 'answered' ? suggestion.answerBy?.name : currentUser.name"
                                  [unity]="suggestion.status === 'answered' ? getMangerTypeByKey(suggestion.answerBy?.profile) : getMangerTypeByKey(currentUser.profile)">
                </app-card-profile>
                <div class="col-md-8">

                    <div>

                        <div class="d-flex justify-content-between flex-grow-1 mb-4" style="gap: 15px;">
                            <h2 class="body-title" style="gap: 0px">
                                {{ 'resposta'|translate }}
                            </h2>
                            <!--ANSWER-->
                        </div>

                        <p *ngIf="suggestion.status === 'answered'" class="body-paragraph">
                            {{ suggestion.answer.toString() }}
                        </p>
                        <div class="input-material" *ngIf="suggestion.status !== 'answered' && selectProfile()">
                            <textarea id="answer" [(ngModel)]="answer.answer"></textarea>
                            <label for="answer"> {{ 'respondaaqui'|translate }}</label>

                        </div>
                    </div>

                    <div>
                        <div class="d-flex flex-column justify-content-between flex-grow-1 mb-4 mt-5" style="gap: 15px;">
                            <h2 class="body-title" style="gap: 0px">
                                {{ 'pontuacao'|translate }}
                            </h2>
                            <p>{{'pontuacaoLegenda'|translate}}</p>
                            <!--ANSWER-->
                        </div>

                        <!--                    <ng-container *ngIf="suggestion?.country.name?.toLowerCase() === 'brazil'">-->
                        <ng-container>
                            <div class="w-100 px-2 pb-2 pt-4 mb-4" style="background-color: #f9f9f9">
                                <div class="row">
                                    <div class="col-4">
                                        <p *ngIf="suggestion.status === 'answered'" class="body-paragraph">
                                            Pontuação: {{ suggestion.score }}
                                        </p>
                                        <div class="input-material mb-2 flex-grow-1">
                                            <select id="score" class="form-control" name="type" [(ngModel)]="answer.score">
                                                <option [value]="null">Selecione</option>
                                                <option [value]="item" *ngFor="let item of [0,1,2,3,4,5]">{{ item }}</option>
                                            </select>
                                            <label for="score" class="mb-0 mr-3">{{ 'score'|translate }}</label>
                                        </div>
                                    </div>
                                    <div class="col-8" *ngIf="suggestion.score == 5 || answer.score?.toString() == '5'">
                                        <div class="input-material"
                                             *ngIf="suggestion.status !== 'answered' && selectProfile()">
                                        <textarea id="justificationScore"
                                                  [(ngModel)]="answer.justificationScore"></textarea>
                                            <label for="justificationScore"> {{ 'justificationScore'|translate }}</label>
                                        </div>
                                        <p *ngIf="suggestion.status === 'answered'" class="body-paragraph">
                                            {{ suggestion.justificationScore }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>

        <div class="row mt-4" style="row-gap: 20px">
            <div class="col-sm-6 d-flex flex-column">
                <span class="body-label text-gray-text mb-2">{{ 'fotos'|translate }}</span>
                <div class="d-flex flex-wrap" style="gap:22px">
                    <ng-container *ngIf="listFilesImg.length === 0"><p
                            class="text-center py-5">{{ 'nenhumaimagem'|translate }}</p></ng-container>
                    <div class="image-box-md" *ngFor="let a of listFilesImg">
                        <a href="{{a.url}}" target="_blank">
                            <img [src]="a.url" alt="photo"/>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 d-flex flex-column">
                <span class="body-label text-gray-text mb-2">{{ 'arquivos'|translate }}</span>
                <div class="d-flex flex-wrap" style="gap:22px">
                    <ng-container *ngIf="listFiles.length === 0"><p
                            class="text-center py-5">{{ 'nenhumarquivo'|translate }}</p></ng-container>
                    <ng-container *ngFor="let a of listFiles">
                        <a href="{{a.url}}" target="_blank">
                        <span class="d-flex align-items-center text-muted" style="gap: 14px">
                            <svg-icon class="btn btn-outline-gray-variant" src="assets/svg/download.svg"></svg-icon>
                            {{ 'arquivo'|translate }}<span class="text-uppercase">{{ a.type }}</span>
                        </span>
                        </a>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-end mt-4 mb-3 mr-3" *ngIf="suggestion.status !== 'answered'">
            <button class="btn btn-warning px-5 mr-3" (click)="actionAnswer()" *ngIf="selectProfile()"
            >{{ 'responder'|translate }}
            </button>
        </div>

        <!--RESPONDIDO-->
        <div class="row col-12 justify-content-between mx-2" *ngIf="suggestion.status === 'answered'">
            <div class="col-8">
                <p class="answer-title">
                    {{ 'resposta'|translate }}
                </p>
                <span class="body-createAt">{{ suggestion.answeredAt | date: 'dd/MM/yyyy' }}</span>
                <br>

                <p *ngIf="suggestion.status === 'answered'" class="body-paragraph">
                    <strong>{{ 'score'|translate }}:</strong> {{ suggestion.score }}<br>
                    <ng-container *ngIf="!isNullOrUndefined(suggestion.justificationScore)">
                        <strong>{{ 'justificationScore'|translate }}:</strong> {{ suggestion.justificationScore }}
                        <br><br></ng-container>
                </p>
                <span class="answer">
                    {{ suggestion.answer }}
                </span>
            </div>
            <div class="d-flex justify-content-between align-items-center card-gray"
                 *ngIf="suggestion.status === 'answered'">
                <div>
                    <h2 class="card-title">Validado por:</h2>
                    {{ suggestion.updatedAt | date: 'dd/MM/yyyy' }}
                </div>
                <app-card-profile
                        [borderImage]="'border-warning'"
                        [image]="!suggestion.answerBy?.photo ? 'assets/svg/person.svg' : suggestion.answerBy?.photo"
                        [name]="suggestion.answerBy?.name"
                        [unity]="suggestion.answerBy?.unity?.name">
                </app-card-profile>
            </div>
        </div>
    </div>
</div>
